import request from '../utils/request'
import baseUrl from './baseUrl'

export const administratorList = (data) => request({
  url: baseUrl + '/user/findUserList',
  method: 'POST',
  data
})
export const delAdmin = (data) => request({
  url: baseUrl + '/user/deleteUser',
  method: 'POST',
  data
})
export const addAdmin = (data) => request({
  url: baseUrl + '/user/addUser',
  method: 'POST',
  data
})
export const updataAdminPwd = (data) => request({
  url: baseUrl + '/user/resetUserPassword',
  method: 'POST',
  data
})
export const editAdmin = (data) => request({
  url: baseUrl + '/user/updateUser',
  method: 'POST',
  data
})


// 入园列表
export const getRegList = (data) => request({
  url: baseUrl + '/parkInfo/selectForBack',
  method: 'POST',
  data
})
// 入园列表-添加/修改
export const regListAdd = (data) => request({
  url: baseUrl + '/parkInfo/add',
  method: 'POST',
  data
})
// 入园列表-添加/修改
export const regListDel = (data) => request({
  url: baseUrl + '/parkInfo/deleteById',
  method: 'POST',
  data
})
export const regListAll = (data) => request({
  url: baseUrl + '/parkUserInfo/selectForBack',
  method: 'POST',
  data
})
export const regListCode = (data) => request({
  url: baseUrl + '/parkInfo/selectErCode',
  method: 'POST',
  data
})
//下载报表
export const downLoadList = (data) => request({
  url: baseUrl + '/parkUserInfo/selectExport',
  method: 'POST',

  data,
  responseType: "blob",
  
})


export const writeOffList = (data) => request({
  url: baseUrl + '/redeem/selectForBack',
  method: 'POST',
  data,

})
export const writeOffListAdd = (data) => request({
  url: baseUrl + '/redeem/add',
  method: 'POST',
  data,

})
export const writeOffListUpdata = (data) => request({
  url: baseUrl + '/redeem/modify',
  method: 'POST',
  data,

})
export const writeOffPeopleList = (data) => request({
  url: baseUrl + '/redeemUser/selectForBack',
  method: 'POST',
  data,

})
export const writeOffPeopleListAdd = (data) => request({
  url: baseUrl + '/redeemUser/add',
  method: 'POST',
  data,

})
export const writeOffPeopleListUpdata = (data) => request({
  url: baseUrl + '/redeemUser/modify',
  method: 'POST',
  data,

})
export const getBaseSet = (data) => request({
  url: baseUrl + '/baseSet/selectForId',
  method: 'POST',
  data,

})
export const outBaseSet = (data) => request({
  url: baseUrl + '/baseSet/modifyOut',
  method: 'POST',
  data,

})
export const baseSetTime = (data) => request({
  url: baseUrl + '/baseSet/modifyWait',
  method: 'POST',
  data,

})
export const writeoffForUserList = (data) => request({
  url: baseUrl + '/userRedeem/selectForBack',
  method: 'POST',
  data,

})
export const writeoffForUserListAdd = (data) => request({
  url: baseUrl + '/userRedeem/add',
  method: 'POST',
  data,

})
export const writeoffForUserListDel = (data) => request({
  url: baseUrl + '/userRedeem/deleteById',
  method: 'POST',
  data,

})
export const writeoffForUserListById = (data) => request({
  url: baseUrl + '/redeem/selectForUserId',
  method: 'POST',
  data,

})
export const InBaseSet = (data) => request({
  url: baseUrl + '/baseSet/modifyIn',
  method: 'POST',
  data,

})

//设置佣金支付方式
export const modifyPayWay = (data) => request({
  url: baseUrl + '/baseSet/modifyPayWay',
  method: 'POST',
  data,

})
//设置扣税模式
export const modifyTax = (data) => request({
  url: baseUrl + '/baseSet/modifyTax',
  method: 'POST',
  data,

})

//操作日志
export const baseSetForBack = (data) => request({
  url: baseUrl + '/baseSet/selectForBack',
  method: 'POST',
  data,

})