<template>
    <div class="add_teacher">
        <el-card class="box-card">
            <el-form ref="ruleForm" :rules="rules" :model="addFrom" label-width="110px">
                <el-form-item label="标题:">
                    设置扣税模式
                </el-form-item>
                <el-form-item label="" prop="info">
                    <!-- 单选 -->
                    <el-radio-group v-model="addFrom.info">
                        <el-radio :label="1">按比例扣税</el-radio>
                        <el-radio :label="2">按法定个税比例扣减佣金个税</el-radio>
                    </el-radio-group>
                    <!-- <div> 外部店长提现设置 0 否 1 是</div> -->
                </el-form-item>
                <el-form-item prop="ratio">
                    <el-input max="99" min="1" v-model="addFrom.ratio" placeholder="比例值" type="number" @blur="validateRatio"
                        v-if="addFrom.info == 1"></el-input>
                </el-form-item>
                <el-form-item>
                    <div style="color: red;" v-if="addFrom.info == 1">请输入0~99的正整数</div>
                </el-form-item>
                <el-form-item>
                    <el-button type="success" @click="submitForm">保存</el-button>
                </el-form-item>
            </el-form>
        </el-card>
        <div class="tables">
            <el-table ref="singleTable" height='250' :data="tableData" highlight-current-row
                @current-change="handleCurrentChange" style="width: 100%">
                <el-table-column type="index" width="50">
                </el-table-column>
                <!-- <el-table-column prop="" label="展示图" align="">
                <template slot-scope="{row}">
                    <img :src="row.showImg" alt="" style=" width: 50px;  height: 50px; object-fit: cover;">
                </template>
            </el-table-column> -->
                <el-table-column property="masterName" label="操作人">
                </el-table-column>
                <el-table-column property="cur" label="修改前内容">
                </el-table-column>
                <el-table-column property="end" label="修改后内容">
                </el-table-column>
                <el-table-column property="addTime" label="操作时间">
                </el-table-column>
            </el-table>
            <div class="block">
                <el-pagination @size-change="handleSizeChange" @current-change="Changes" :current-page="currentPage"
                    :page-sizes="[10, 20, 30, 40]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
                    :total="totalItems">
                </el-pagination>
            </div>
        </div>
    </div>
</template>
<script>
import { modifyTax, baseSetForBack, getBaseSet } from "../../api/administrator.js";
export default {
    data() {
        return {
            addFrom: {
                info: 1,
                ratio: "",
            },
            tableData: [],
            currentPage: 1,
            pageSize: 10,
            totalItems: null,
            currentRow: '',
            errorMessage: '',
            rules: {
                ratio: [
                    { required: true, message: '比例值不能为空', trigger: 'blur' },
                    { pattern: /^(0|[1-9][0-9]?)$/, message: '请输入0~99的正整数', trigger: 'blur' }
                ],
            },
        };
    },
    created() {
        this.initDate()
        this.getBase()
    },
    methods: {
        getBase() {
            getBaseSet({ id: 6 }).then((res) => {
                if (res.data.code == 0) {
                    this.addFrom.title = res.data.data.title;
                    this.addFrom.ratio = res.data.data.ratio;
                    this.addFrom.info=res.data.data.info;
                    this.this.initDate()
                }
            });
        },
        submitForm() {
            this.$refs.ruleForm.validate((valid) => {
                if (valid) {
                    this.$confirm('设置扣税模式吗？', '确认', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        // 用户点击了“确定”按钮
                        if (this.addFrom.info == 1) {
                            // this.addFrom.ratio
                            modifyTax({
                                info: this.addFrom.info,
                                ratio: this.addFrom.ratio
                            }).then((res) => {
                                if (res.data.code == 0) {
                                    this.$message.success(res.data.msg);
                                    this.initDate()
                                }
                                console.log(res);
                                // 可以在这里添加更多的处理逻辑
                            }).catch((error) => {
                                console.error('提交失败:', error);
                            });
                        } else {
                            modifyTax({
                                info: this.addFrom.info
                            }).then((res) => {
                                if (res.data.code == 0) {
                                    this.initDate()
                                    this.$message.success(res.data.msg);
                                }
                                console.log(res);
                                // 可以在这里添加更多的处理逻辑
                            }).catch((error) => {
                                console.error('提交失败:', error);
                            });
                        }
                    }).catch(() => {
                        // 用户点击了“取消”按钮
                        console.log('用户取消了评分提交');
                    });
                } else {
                    console.error('表单验证失败!');
                }
            });
        },
        initDate() {
            baseSetForBack({ baseId: 6, pageSize: this.pageSize, currentPage: this.currentPage }).then((res) => {
                this.tableData = res.data.list
                this.totalItems = res.data.pagination.total
                console.log(99, this.totalItems);
            })

        },
        handleSizeChange(val) {
            this.pageSize = val // 每次修改 pageSize 时，重置到第一页
            this.initDate(); // 重新加载数据
            console.log(`每页 ${val} 条`);
        },
        Changes(val) {
            this.currentPage = val;
            this.initDate()
            console.log(`当前页: ${val}`);
        },
        handleCurrentChange(val) {
            this.currentRow = val;
            console.log(val, 777);
        },
    },
};
</script>
  
<style scoped>
.avatar-uploader-icon {
    border: 1px dashed #eee;
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
}

.avatar {
    width: 150px;
    height: 150px;
}

.box-card {
    padding-bottom: 50px;
}

.el-input {
    width: 400px;
}

.tables {
    width: 100%;
    height: 100%;
    position: relative;
}

.block {
    position: absolute;
    right: 20px;
    bottom: -50px;
}
</style>
  